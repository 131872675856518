import request from "./http"


/**
 * @description 查询分页数据
 *  @param  {String} limit 多少页
 *  @param  {String} page 第几页
 * @return 返回响应数据
 */
export function getCompanyList(limit, page, data) {
    return request({
        url: `/api/admin/company/list?limit=${limit}&page=${page}`,
        method: "post",
    })
}


/**
 * @description 新增数据
 *  @param  {String} limit 多少页
 *  @param  {String} page 第几页
 *  @param  {Object} data 数据
 * @return 返回响应数据
 */
export function addCompany(data) {
    return request({
        url: `/api/admin/company/create`,
        method: "post",
        data
    })
}


/**
 * @description 修改数据
 *  @param  {String} id id
 *  @param  {Object} data 数据
 * @return 返回响应数据
 */
export function updateCompany(data) {
    return request({
        url: `/api/admin/company/update`,
        method: "post",
        data
    })
}


/**
 * @description 删除数据
 *  @param  {String} id id
 *  @param  {Object} data 数据
 * @return 返回响应数据
 */
export function delCompany(id) {
    return request({
        url: `/api/admin/company/delete/${id}`,
        method: "post",
    })
}


/**
 * @description 根据id查询数据
 *  @param  {String} id id
 * @return 返回响应数据
 */
export function queryCompany(id) {
    return request({
        url: `/api/admin/company/${id}`,
        method: "get",
    })
}