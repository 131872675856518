<template>
  <div class="about-us">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      @_add="_add"
      @_query="_query"
      @_export="_export"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      @_del="_del"
      @_edit="_edit"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
      @_handleSelectionChange="_handleSelectionChange"
    >
    </CommonTable>
    <!-- 弹框表单 -->
    <CommonDialogForm
      ref="form"
      :form="form"
      :visible="visible"
      :title="dialogTitle"
      :formItemList="formItemList"
      @_Close="_Close"
      @_cancel="_cancel"
      @_confirm="_confirm"
    >
    </CommonDialogForm>
  </div>
</template>

<script>
// 引入接口
import {
  getCompanyList,
  addCompany,
  updateCompany,
  delCompany,
  queryCompany,
} from "../../api/aboutUs.js"
export default {
  name: "AboutUs",
  data() {
    return {
      // 查询表单
      queryForm: {
        id: "",
      },

      // 查询表单项
      queryFormItemList: [
        // { type: "input", prop: "id", placeholder: "请输入要查询的id" },
      ],

      // 加载
      loading: false,

      // 表格数据
      tableData: [],

      // 表头列
      columns: [
        { label: "名称", prop: "name" },
        { label: "内容", prop: "content" },
      ],

      // 选择的表格数据
      selectChangeTableData: [],

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },

      form: {
        name: "",
        content: "",
      },

      formItemList: [
        {
          label: "名称",
          type: "input",
          prop: "name",
          value: "name",
          placeholder: "请输入名称",
        },
        {
          label: "内容",
          type: "input",
          prop: "content",
          value: "content",
          placeholder: "请输入内容",
        },
      ],

      dialogTitle: "",

      visible: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 添加
    _add() {
      this.dialogTitle = "添加公司"
      this.visible = true
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
          this.$refs.form.$refs.form.resetFields()
        })
      })
    },

    // 查询
    _query() {
      // console.log("查询")
      this.getData()
    },

    // 导出
    _export(form) {
      console.log("导出", form)
      import("../../vendor/Export2Excel").then((excel) => {
        const tHeader = getExcelLabelOrProp("编号", "label", this.columns)
        const filterVal = getExcelLabelOrProp("serial", "prop", this.columns)
        const list =
          this.selectChangeTableData.length > 0
            ? this.selectChangeTableData
            : this.tableData
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "公司管理",
          bookType: "xlsx",
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    // 删除
    async _del(row) {
      // console.log("删除")
      this.$confirm("你确定要删除此项吗？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            const res = await delCompany(row.id)
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            })
            this.getData()
          } catch (error) {
            this.$message({
              showClose: true,
              message: "删除失败",
              type: "error",
            })
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },

    // 编辑
    _edit(row) {
      // console.log("编辑",row)
      this.dialogTitle = "修改公司"
      this.visible = true
      this.form = JSON.parse(JSON.stringify(row))
    },

    // 一页多少条
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 切换分页
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 多选导出
    _handleSelectionChange(val) {
      this.selectChangeTableData = val
    },

    // 确认
    _confirm() {
      if (this.dialogTitle === "添加公司") {
        this.add()
      }
      if (this.dialogTitle === "修改公司") {
        this.edit()
      }
    },

    async edit() {
      try {
        const res = await updateCompany(this.form)
        // console.log("修改公司", res)
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          })
          this.visible = false
          this.getData()
        }
      } catch (error) {
        this.visible = false
        this.$message({
          showClose: true,
          message: "修改失败",
          type: "error",
        })
      }
    },

    async add() {
      try {
        const res = await addCompany(this.form)
        // console.log("添加公司", res)
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: "添加成功",
            type: "success",
          })
          this.visible = false
          this.getData()
        }
      } catch (error) {
        this.visible = false
        this.$message({
          showClose: true,
          message: "添加失败",
          type: "error",
        })
      }
    },

    // 表单关闭按钮
    _Close() {
      this.closeForm()
    },

    // 关闭表单
    closeForm() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.resetFields()
        })
      })
      this.visible = false // 关闭弹框
    },

    // 获取数据
    async getData() {
      this.loading = true
      try {
        this.loading = false
        const { limit, page } = this.pagingOptions
        const res = await getCompanyList(limit, page)
        // console.log("获取数据", res)
        this.loading = false
        this.tableData = res.data.list
        this.pagingOptions.total = res.data.total
      } catch (error) {
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },

    // 取消
    _cancel() {
      this.closeForm()
    },

    // 关闭表单
    closeForm() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
        })
      })
      this.visible = false // 关闭弹框
    },
  },
}
</script>

<style lang="scss" scoped>
.about-us {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
</style>